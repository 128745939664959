// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-inspiratie-js": () => import("./../../../src/pages/inspiratie.js" /* webpackChunkName: "component---src-pages-inspiratie-js" */),
  "component---src-pages-recepten-js": () => import("./../../../src/pages/recepten.js" /* webpackChunkName: "component---src-pages-recepten-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-case-js": () => import("./../../../src/templates/Case.js" /* webpackChunkName: "component---src-templates-case-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/Recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

